import React from "react";

const NoBoardView = () => {
  
  return (
    <div>404 No Board found.</div>
  )
};

export default NoBoardView;
