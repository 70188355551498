import React from "react";

const HeaderBuffer = () => {

  return (
    <div
      style={{
        "height": "100px",
        "width": "0px",
      }}
    />
  )
}

export default HeaderBuffer;
